import React, { useState } from 'react';
import BluetoothControl from '../BluetoothControl/BluetoothControl';
import BluetoothControlCalibration from '../BluetoothControl/BluetoothControlCalibration';
import Wizards3ClientComponent from './Wizards3ClientComponent';
import CoinBoxCalibratorComponent from '../CoinBoxCalibratorComponent';
import { useAuth } from '../../contexts/AuthContext';

const Wizard3Main = () => {
  const { currentUser } = useAuth();
  const [CoinBox, setCoinbox] = useState(null);
  const [CoinBoxCalibrator, setCoinboxCalibrator] = useState(null);
  return (
    <div className="min-h-screen bg-gray-50 p-4 w-full">
      {!CoinBox && !CoinBoxCalibrator &&
        <div>
          <h1 className="text-xl font-bold mb-4">{currentUser.email}</h1>
        </div>
      }
      {!CoinBoxCalibrator && <BluetoothControl setCoinbox={setCoinbox} />}
      {!CoinBoxCalibrator && CoinBox && <Wizards3ClientComponent coinbox={CoinBox} />}

      {!CoinBox &&<BluetoothControlCalibration setCoinbox={setCoinboxCalibrator} />}
      {!CoinBox && CoinBoxCalibrator && <CoinBoxCalibratorComponent coinbox={CoinBoxCalibrator} />}
    </div>
  );
};
export default Wizard3Main;