import ServerlessClientBase from './ServerlessClientBase';

class CoinBoxCalibrator extends ServerlessClientBase {
    constructor(coinbox, onGameStateChange = null, onCardStateChange = null) {
        super(coinbox);

        this.gatherCalibrationData = [];
        this.safetyAngels = 6;
        console.log('CoinBoxCalibrator constructor');
        this.coinbox.on('calibration', this.handleCalibrationEvent);
        this.partsToCalibrate = [0x05, 0x06, 0x07, 0x08];
        this.safetyAngels = {
            0x05:12, 
            0x06:10,
            0x07:4,
            0x08:12
        };
        this.calculatingPositions = false;
        this.partId = 0;
        this.startCalibration();
    }

    startCalibration() {
        console.log('Starting calibration');
        this.sendStartCalibrateCommand();
    }

    handleCalibrationEvent = (event) => {
        if(this.calculatingPositions) return;
        //console.log('Calibration event:', event);
        const { partId, angle, amp } = event;
        if(partId !== this.partId) return;
        this.gatherCalibrationData.push({ partId, angle, amp });
        if (this.gatherCalibrationData.length === 360) {
            this.calculateCalibration();
        }
    }

    async calculateCalibration() {
        console.log('Calculating calibration');
        this.calculatingPositions = true;

        const partId = this.partId
    
        // Step 1: Group AMP readings by angle
        const calibrationData = this.gatherCalibrationData.reduce((acc, data) => {
            if (data.partId !== partId) {
                return acc;
            }
            if (!acc[data.angle]) {
                acc[data.angle] = [];
            }
            acc[data.angle].push(data.amp);
            return acc;
        }, {});
    
        // Step 2: Convert grouped data into an array and sort by angle
        const sortedData = Object.keys(calibrationData).map(angle => ({
            angle: parseInt(angle),
            amp: calibrationData[angle].reduce((a, b) => a + b, 0) / calibrationData[angle].length,
        })).sort((a, b) => a.angle - b.angle);
    
        // Step 3: Smooth the data using a moving average
        const smoothedData = [];
        const windowSize = 5;
        for (let i = 0; i < sortedData.length - windowSize + 1; i++) {
            const window = sortedData.slice(i, i + windowSize);
            const averageAmp = window.reduce((sum, point) => sum + point.amp, 0) / windowSize;
            smoothedData.push({
                angle: sortedData[i + Math.floor(windowSize / 2)].angle,
                amp: averageAmp,
            });
        }
    
        // Step 4: Calculate the base AMP value from angles 80 to 120
        const baseAmpValues = smoothedData
            .filter(point => point.angle >= 80 && point.angle <= 120)
            .map(point => point.amp);
        const baseValue = baseAmpValues.reduce((a, b) => a + b, 0) / baseAmpValues.length;
    
        // Step 5: Identify significant drops in AMP readings (more than 2% lower than base value)
        const threshold = baseValue * 0.97; // 3% drop
        const hitsWall = smoothedData.map(point => ({
            angle: point.angle,
            hit: point.amp < threshold, 
        }));
    
        // Step 6: Remove isolated true values (single points where 'hit' is true)
        const cleanedHitsWall = hitsWall.map((point, index, array) => {
            if (point.hit) {
                const prevHit = index > 0 ? array[index - 1].hit : false;
                const nextHit = index < array.length - 1 ? array[index + 1].hit : false;
                if (!prevHit && !nextHit) {
                    return { angle: point.angle, hit: false };
                }
            }
            return point;
        });
    
        // Step 7: Detect edges where 'hit' status changes
        const positions = [];
        for (let i = 1; i < cleanedHitsWall.length; i++) {
            if (cleanedHitsWall[i - 1].hit !== cleanedHitsWall[i].hit) {
                positions.push(cleanedHitsWall[i].angle);
            }
        }
    
        // Step 8: Calculate final positions based on detected edges
        if (positions.length >= 2) {
            // Assuming positions[0] is where the servo starts hitting the wall,
            // and positions[1] is where it stops hitting
            const position2 = Math.round(positions[0])+this.safetyAngels[this.partId];
            const position0 = Math.round(positions[1])-this.safetyAngels[this.partId];
            const position1 = Math.round((positions[0] + positions[1]) / 2); // Midpoint between position0 and position2 
    
            console.log('Calibration completed for partId:', partId);
            console.log('Calibration positions:', {
                position0,
                position1,
                position2,
            });

            const finalPositions = [];
            finalPositions[0] = position0;
            finalPositions[1] = position1;
            finalPositions[2] = position2;

            await this.coinbox.manager.saveCalibrationData(this.partId, finalPositions);

        } else {
            console.log('Not enough transitions detected for calibration.');
            // lets reinsert the partId back to the partsToCalibrate
            this.partsToCalibrate.unshift(partId);
        }
    
        this.calculatingPositions = false;

        this.sendStartCalibrateCommand();
    }
    
    /*
    [0] Part Identifier (1 byte): Identifies whether the message is regarding the top part (0x01) or the bottom part (0x02), color sensor (0x03), ID part = (0x04), top_clicker (0x05), top_mover (0x06), bottom_clicker (0x07), bottom_mover (0x08), box(0x09), auto_eat(0x0A),light sensor(0x0B), color in slots(0x0C),  color(0x0D), tray_servo (0x0E),  colorled (0x0F), vision (0x10)
[1] Command/Status Type (1 byte): Specifies the type of message (e.g., command to start sequence = 0x01, status update = 0x02, configuration = 0x03, box = 0x04, color reading = 0x05, vision = 0x06,  calibration = 0x07
[2] Sequence Position/Color or Slot Number (1 byte): Holds the position in the sequence (1-X) for status updates, or the color/slot number (1-6) for commands.
[3] Additional Data (1 byte): Reserved for future use or additional parameters, initialized to 0x00 for now.
[4] Additional Data (1 byte): Reserved for future use or additional parameters, initialized to 0x00 for now.
*/
    async sendStartCalibrateCommand() {
        // pick the next part from this.partsToCalibrate
        if(this.partsToCalibrate.length === 0) {
            console.log('Calibration completed');
            const command = new Uint8Array([0x09, 0x04, 0x02, 0x00]);
            await this.coinbox.manager.enqueueCommand(command);
            return;
        }   
        this.gatherCalibrationData = [];
        this.partId = this.partsToCalibrate.shift();
        const command = new Uint8Array([this.partId, 0x07, 0x00, 0x00]);
        await this.coinbox.manager.enqueueCommand(command);
    }

}

export default CoinBoxCalibrator;